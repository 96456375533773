<template>
  <div>
    <ChangeMobileNumber />
    <div class="auth-wrapper overflow-hidden">
      <div class="container-fluid no-pdd">
        <div class="row align-items-center no-gutters">
          <div class="col-lg-6 d-none d-lg-block no-pdd">
            <Left />
          </div>
          <div class="col-lg-6 no-pdd">
            <div class="auth-side forgot-section">
              <div class="code-verification">
                <AuthTop class="ml-0" />
                <a style="margin-top: 37px;" @click="removeItemsFromStorage()">Logout</a>

              </div>
              <form
                
                class="sign-in-form forgot-form"
                id="code-form-wrapper"
                @keyup.enter="verifyMobilecode(0)"
                @submit.prevent
              >
                <h4>Mobile Number Registration</h4>
                <!-- Error and Success Messages -->
                <p v-if="errorMessage != null" class="tag-line" style="color: red">
                  {{ errorMessage }}
                </p>
                <p v-if="resendMessage != null" class="tag-line" style="color: green">
                  {{ resendMessage }}
                </p>
                <div class="d-flex">
                  <!-- Code Select -->
                  <div class="form-group phone-code w-25 pr-2">
                    <b-form-select
                      v-model="phonedetails.mob_code"
                      :options="codeList"
                      v-on:keyup="errorNull"
                    >
                      <template #first></template>
                    </b-form-select>
                  </div>
                  <!-- Phone Number Input -->
                  <div class="form-group flex-grow-1">
                    <input
                      id="mob_phone"
                      class="form-control"
                      v-model.trim="phonedetails.mob"
                      spellcheck="false"
                      autocomplete="off"
                      :class="{
                        'is-invalid': validationStatus($v.phonedetails.mob),
                      }"
                      trim
                      required
                      type="text"
                      v-on:keyup="errorNull"
                    />
                    <span class="placeholder">Mobile Number</span>
                    <div v-if="!$v.details.mob.required" class="invalid-tooltip">
                     Mobile number is required
                    </div>
                    <div v-if="!$v.details.mob.minLength" class="invalid-tooltip">
                      Minimum length should be 10
                    </div>
                    <div v-if="!$v.details.mob.integer" class="invalid-tooltip">
                      Mobile number should be a number
                    </div>
                  </div>
                </div>
                <!-- Buttons -->
                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="verifyMobileNumber(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Continue</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Continue</span>
                  </button>
                </div>
                <div>
                  <a @click="bypassMobileVerification()">Remind me later</a>
                </div>
              </form>
              <AuthBottom />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import Left from "../../components/AuthLeft/Left.vue";
import AuthTop from "../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../components/AuthLeft/AuthBottom.vue";
import ChangeMobileNumber from "./ChangeMobileNumber.vue";

export default {
  name: "RegisterMobile",
  components: { Left, AuthTop, AuthBottom, ChangeMobileNumber },
  data: function() {
    return {
      errorMessage: null,
      phonedetails: {
        mob: "",
        mob_code: "+1",
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
      isLoadingArray: [],
      isLoadingArrayResend: [],
      details: {
        code: "",
        mob: "",
      },
      resendMessage: null,
      timerCountMobile: 60,
      isDisabled: true,
      changeBtn: false,
      intervalId: null,
    };
  },
  validations: {
    phonedetails: {
      mob: { required, integer, minLength: minLength(10) },
    },
    details: {
      mob: { required, integer, minLength: minLength(10) },
      code: {
        required,
        integer,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["group"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("auth", ["founderPayment"]),
    isNegative() {
      return this.timerCountMobile < 0;
    },
  },

  methods: {
    ...mapActions("auth", ["verifyMobile"]),
    ...mapActions("auth", ["verifyMobileRegisterRequest"]),
    ...mapActions("auth", ["verifyMobileNumber", "getUserData"]),
    ...mapActions("auth", ["getFounderPayment"]),
    validationStatus: function(validation) {
      console.log(validation)
      return typeof validation != "undefined" ? validation.$error : false;
    },
    onPaste(event) {
      if (event.inputType === "insertFromPaste") {
        this.errorNull();
      }
    },
    verifyMobilecode: function(index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.mob = localStorage.getItem("mobileToVerify");
      this.verifyMobile(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          if (localStorage.getItem("invite_count") == 0) {
            localStorage.removeItem("mobileToVerify");
            localStorage.removeItem("emailToVerify");
            this.$router.push({ name: "Teams" });
          } else {
            localStorage.removeItem("mobileToVerify");
            localStorage.removeItem("emailToVerify");
            this.$router.push({ name: "PendingInvite" });
          }
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },
    verifyMobileNumber: function(index) {
  this.errorMessage = null;
  this.isDisabled = true;

  if (this.$v.$pending || this.$v.$error) return;
  this.$set(this.isLoadingArray, index, true);

  var formData = new FormData();
  var formData1 = new FormData();
  formData1.append("mob_code", this.phonedetails.mob_code);
  formData1.append("phone", this.phonedetails.mob);
  formData.append("mob_code", this.phonedetails.mob_code);
  formData.append("mob", this.phonedetails.mob);

  axios
    .post(
      process.env.VUE_APP_API_URL + "user/mobileUpdateViaCode",
      formData
    )
    .then((response) => {
      if (response.data.statusCode === 200) {
        this.getUserData();
        console.log("here is" + this.user.mobile_verification)
        if (this.user.mobile_verification == 1) {
          this.$router.push({ name: "VerifyMobile" });
        }else{
          this.$router.push({ name: "VerifyMobile" });
        }

        // Call the verifyMobileRegisterRequest action here
        this.verifyMobileRegisterRequest(formData1).then((verifyResponse) => {
          // Handle the response of verifyMobileRegisterRequest if needed
          console.log(verifyResponse)
        });
      } else {
        setTimeout(() => {
          this.$set(this.isLoadingArray, index, false);
          this.errorMessage = response.data.message;
        }, 500);
      }
    })
    .catch((error) => {
      this.$set(this.isLoadingArray, index, false);
      this.errorMessage = error;
    });
},


    reSend(index) {
      this.changeBtn = false;
      this.resendMessage = null;
      this.errorMessage = null;
      this.timerCountMobile = 0;
      this.$set(this.isLoadingArrayResend, index, true);
      var formData = new FormData();
      formData.append("phone", localStorage.getItem("mobileToVerify"));
      formData.append("mob_code", localStorage.getItem("mobileCodeToVerify"));
      this.verifyMobileRegisterRequest(formData).then((response) => {
        if (response.data.statusCode == 200) {
          this.$set(this.isLoadingArrayResend, index, false);
          this.timerCountMobile = 60;
          this.getFounderPayment();
        } else {
          setTimeout(() => {
            this.changeBtn = true;
            this.timerCountMobile = 0;
            this.$set(this.isLoadingArrayResend, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    decrementCounter() {
      this.timerCountMobile--;
      if (this.timerCountMobile == 0) {
        this.changeBtn = true;
      }
    },
    errorNull: function() {
      this.errorMessage = null;
      this.$nextTick(() => {
        if (this.$v.$invalid && this.$v.phonedetails.mob.$invalid) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
      });
    },
    bypassMobileVerification() {
      localStorage.removeItem("mobileCodeToVerify");
      localStorage.removeItem("mobileToVerify");
      if (localStorage.getItem("invite_count") != 0) {
        this.$router.push({ name: "PendingInvite" });
      } else {
        this.$router.push({ name: "Teams" });
      }
    },
    removeItemsFromStorage() {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("user_link");
      localStorage.removeItem("pro_status");
      localStorage.removeItem("pro_expiry");
      localStorage.removeItem("emailToVerify");
      localStorage.removeItem("mobileToVerify");
      localStorage.removeItem("mobileCodeToVerify");
      localStorage.removeItem("linkUrl");
      localStorage.removeItem("notifyId");
      this.$router.push({ name: "Login" });
    },
    changeMobileNumber() {
      this.$root.$emit("openChangeMobileNumberOnRegister");
    },
    email: function() {
      alert();
      this.$router.push({ name: "Email" });
    },
  },

  created() {
    this.intervalId = setInterval(() => {
      this.decrementCounter();
    }, 1000); // Update every second
  },

  destroyed() {
    clearInterval(this.intervalId);
  },

  mounted() {
    // document.getElementById("email").addEventListener("input", this.onPaste);
    this.$store.commit("setErrors", {});
    // document.getElementById("code-form-wrapper").click();
    this.reSend(0);
    this.$root.$on("resetTimer", () => {
      this.timerCountMobile = 60;
    });
  },
  // watch: {
  //   timerCountMobile: {
  //     handler(value) {
  //       if (value > 0) {
  //         setTimeout(() => {
  //           this.timerCountMobile--;
  //         }, 1000);
  //
  //       }
  //     },
  //     immediate: true, // This ensures the watcher is triggered upon creation
  //   },
  // },
};
</script>
